import { useAuth0 } from "@auth0/auth0-react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/Check";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import SchoolIcon from "@material-ui/icons/School";
import TreeItem from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import DoneIconImg from "@material-ui/icons/Done";
import "animate.css";
import PropTypes from "prop-types";
import React from "react";
import SwipeableViews from "react-swipeable-views";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { ImCalculator } from "react-icons/im";
import PeriodsTable from './periodsTable';
import "./style.css";
import { apiurl } from '../app/prefs.js';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
}));

function StatusTreeView(props) {
  const renderTree = (nodes) =>
    Array.isArray(nodes) ? (
      nodes.map((node, nodeId) => (
        <TreeItem
          key={nodeId}
          nodeId={nodeId}
          label={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                lineHeight: "30px",
              }}
            >
              {node.status === "tip" ?
                <SchoolIcon style={{ fontSize: "22px", marginRight: "10px", color: "#FE8E3C" }} />
                :
                node.status === "ok" ? (
                  <CheckIcon style={{ fontSize: "22px", marginRight: "10px", color: "#0b6471" }} />
                ) : (node.status === 'warn' ?
                  <NotListedLocationIcon
                    style={{ fontSize: "22px", marginRight: "10px", color: "#EAC424" }}
                  /> :
                  <PriorityHighIcon
                    style={{ fontSize: "22px", marginRight: "10px", color: "#FE8E3C" }}
                  />
                )}
              <span>{node.name}</span>
            </div>
          }
        >
          {Array.isArray(node.children)
            ? node.children.map((subnode) => renderTree(subnode))
            : null}
        </TreeItem>
      ))
    ) : (
      <div
        style={{
          color: "rgb(100,100,100)",
          marginLeft: "15px",
          marginTop: '3px',
        }}
        key={nodes.id}
        nodeId={nodes.id}
        dangerouslySetInnerHTML={{ __html: nodes.name }}
      />
    );

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpanded={[]}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {renderTree(props.data)}
    </TreeView>
  );
}

export default function ImportData() {
  const { getAccessTokenSilently, user } = useAuth0();
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [progress, setProgress] = React.useState(0);
  const [importStatus, setImportStatus] = React.useState([]);
  const [phase, setPhase] = React.useState(0);
  const [dataStatus, setDataStatus] = React.useState("none");
  const [openTopics, setOpenTopics] = React.useState([0]);
  const [visible, setVisible] = React.useState([0]);
  const updatePhase = () => {
    if (dataStatus === "loaded")
      setTimeout(
        () =>
          setPhase((pV) => {
            if (pV < 12) {
              if (pV === 10) {
                setVisible([0, 1, 2]);
              }
              if (pV === 9) {
                setOpenTopics([]);
              }
              if (pV === 11) {
                setOpenTopics([1]);
              }
              return pV + 1;
            } else return pV;
          }),
        [300, 300, 300, 300, 300, 300, 300, 300, 300, 1000, 550, 0][phase]
      );
    if (dataStatus === "error")
      setTimeout(
        () =>
          setPhase((pV) => {
            if (pV < 5) {
              if (pV === 3) {
                setVisible([0, 1, 2]);
              }
              if (pV === 2) {
                setOpenTopics([]);
              }
              if (pV === 4) {
                setOpenTopics([1]);
              }
              return pV + 1;
            } else return pV;
          }),
        [200, 200, 1800, 200, 0][phase]
      );
  };

  const [rowArray, setRowArray] = React.useState([
    "Przesyłanie i weryfikacja pliku"
  ]);
  React.useEffect(updatePhase, [phase, dataStatus, rowArray]);

  const StatusTable = () => {
    const WaitIcon = () => (
      <div
        className="loader"
        style={{ marginRight: "10px", width: "15px", height: "15px" }}
      ></div>
    );
    const DoneIcon = () => (
      <div style={{ marginRight: "10px", width: "20px", color: "#0B6471" }}>
        <DoneIconImg />
      </div>
    );
    const ErrorIcon = () => (
      <div style={{ marginRight: "10px", width: "20px", color: "#FE8E3C" }}>
        <DoneIconImg />
      </div>
    );
    return (
      <div>
        <table>
          <tbody>
            {rowArray.map((row, rowId) => (
              <tr
                key={rowId}
                style={{
                  minHeight: "30px",
                  display: "flex",
                  alignItems: "flex-start"
                }}
              >
                <td style={{ marginTop: "-5px" }}>
                  {dataStatus === "loading" || phase < rowId ? (
                    <WaitIcon />
                  ) : dataStatus === "error" ? (
                    <ErrorIcon />
                  ) : (
                    <DoneIcon />
                  )}
                </td>
                <td
                  style={{
                    color:
                      dataStatus === "loading" || phase < rowId
                        ? "lightgray"
                        : "black"
                  }}
                >
                  {row}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div
          style={{
            display: "flex",
            justifyItems: "left",
            height: "40px",
            width: "100%"
          }}
        ></div>
      </div>
    );
  };

  const tableData = [
    {
      header: (
        <div
          style={{
            textAlign: "left",
            //width: "400px"
          }}
          className={
            phase > rowArray.length - 1 && dataStatus !== "loading"
              ? dataStatus === "loaded"
                ? phase < 10
                  ? "fancyEntryVisible"
                  : "fancyEntrySolid"
                : phase < 3
                  ? "fancyErrorVisible"
                  : "fancyErrorSolid"
              : "fancyEntryHidden"
          }
        >
          {dataStatus === "loaded"
            ? "Zaimportowano dane za: " + importStatus[0].children[0].name
            : "Nie zaimportowano danych"}
        </div>
      ),
      content: (
        <div>
          <StatusTable />
        </div>
      )
    },
    dataStatus === 'loaded' ? {
      header: "Alerty merytoryczne",
      content: (
        <div style={{ maxHeight: '580px', overflow: 'scroll' }}>
          <StatusTreeView data={importStatus.filter(item => (dataStatus === 'loaded' && (item.status === 'tip')))} />
        </div>
      )
    } : {
      header: "Błędy w pliku",
      content: (
        <div style={{ maxHeight: '580px', overflow: 'scroll' }}>
          <StatusTreeView data={importStatus.filter(item => ((dataStatus === 'error' && (item.status === 'error' && item.id !== 'pr'))))} />
        </div>
      )
    },
    dataStatus === 'loaded' && {
      header: "Puste arkusze", content: <div>
        <StatusTreeView data={importStatus.filter(item => (dataStatus === 'loaded' && (item.status === 'warn')))} />
      </div>
    }
  ];

  const Expandable = (props) => {
    const toggle = (rowID) => {
      if (openTopics.includes(rowID)) {
        setOpenTopics((pv) => pv.filter((item) => item !== rowID));
      } else {
        setOpenTopics((pv) => pv.concat(rowID));
      }
    };

    const Row = (props) => {
      const rowData = props.rowData;
      const rowID = props.rowID;
      const isOpen = () => openTopics.includes(rowID);
      return (
        <div
          key={rowID}
          className={visible.includes(rowID) ? "key visible" : "key hidden"}
        >
          <div className="header visible">
            <div
              className={
                phase < (dataStatus === "loaded" ? 10 : 3)
                  ? "header hidden"
                  : "header visible"
              }
            >
              <div onClick={() => toggle(rowID)} style={{ cursor: "pointer" }}>
                <div className={!isOpen() ? "exp1open" : "exp1close"} id="exp1">
                  <svg viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg">
                    <rect width="23" height="3" x="0" y="10" />
                  </svg>
                </div>
                <div className="exp2">
                  <svg viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg">
                    <rect width="23" height="3" x="0" y="10" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="header visible topic">{rowData.header}</div>
          </div>
          <div
            id={"con" + rowID}
            className={isOpen() ? "content visible" : "content hidden"}
          >
            {rowData.content}
          </div>
        </div>
      );
    };
    return (
      <div>
        {props.rows.map((rowData, rowID) =>
          Row({ key: rowID, rowID: rowID, rowData: rowData })
        )}
      </div>
    );
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const ImportFile = (props) => {
    const handleFileImport = (file) => {
      getAccessTokenSilently().then((token) => {
        const req = new XMLHttpRequest();

        req.upload.addEventListener("progress", (event) => {
          if (event.lengthComputable) {
            setProgress((event.loaded / event.total) * 100);
          }
        });

        req.onload = () => {
          const reqData = JSON.parse(req.response);
          setImportStatus(reqData);
          if (reqData && reqData.length !== 0 && reqData[0].status === 'ok' && reqData[0].children && reqData[0].children.length === 1) {
            setRowArray([
              "Przesyłanie i weryfikacja pliku",
              "Weryfikacja poprawności zestawień",
              "Wykonanie alokacji OPK Pomocniczych (kont 530, 535)",
              <div>
                Wykonanie alokacji OPK Proceduralnych (kont 507)
                <ul
                  style={{
                    textAlign: "left",
                    margin: "0px",
                    padding: "5px 0px 0px 20px"
                  }}
                >
                  <li>Wyliczenie kosztów pośrednich procedur medycznych</li>
                  <li>Wyliczenie kosztów wytworzenia procedur medycznych, w oparciu o jednostkę kalkulacyjną lub proporcjonalnie do czasu trwania procedur</li>
                </ul>
              </div>,
              "Wykonanie alokacji OPK Zarządu (kont 550)",
              "Wyliczenie odchylenia wykonania od budżetu (w przypadku zaimportowania danych budżetowych)",
              "Wyliczenie kosztów i rentowności pacjentów oraz JGP (dla pakietu „CO+ Zaawansowany”)",
              "Wygenerowanie zestawu raportów zarządczych gotowych do analizy",
              "Wygenerowanie zestawu alertów merytorycznych"

            ]);
            setDataStatus('loaded')
          } else {
            setRowArray(["Przesyłanie i weryfikacja pliku", "Weryfikacja poprawności zestawień"]);
            setDataStatus('error');
          }
        };

        req.upload.addEventListener("error", (event) => {
          alert('Wystąpił błąd podczas przesyłania pliku, proszę spróbować ponownie. Jeżeli problem będzie się powtarzał prosimy o kontakt z serwisem COplus.')
          setDataStatus('error');
        });

        const formData = new FormData();

        formData.append("file", file, file.name);
        formData.append("option", props.option);
        req.open(
          "POST",
          `${apiurl}/uploadsrkfile?` + new Date().getTime()
        );
        req.setRequestHeader("Authorization", "Bearer " + token);
        req.send(formData);
        setDataStatus('loading');
        setPhase(0);
        setRowArray(["Przesyłanie i weryfikacja pliku"]);
        setImportStatus([]);
        setOpenTopics([0]);
        setVisible([0]);
      });
    };

    function CircularProgressWithLabel(props) {
      return (
        <Box position="relative" display="inline-flex">
          <CircularProgress
            color={props.value === 100 ? "primary" : "secondary"}
            variant="static"
            {...props}
          />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              variant="caption"
              component="div"
              color="textSecondary"
            >{`${Math.round(props.value)}%`}</Typography>
          </Box>
        </Box>
      );
    }
    return (
      <Paper
        style={{
          width: "450px",
          height: "75px",
          backgroundColor: "rgb(250,250,250)",
          borderRadius: "5px",
          display: "flex",
          flex: "auto",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "10px",
          marginTop: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flex: "auto",
            alignItems: "center",
          }}
        >
          <input
            disabled={dataStatus === 'loading' || props.disabled}
            style={{ display: "none" }}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            className={classes.input}
            id={"contained-button-file" + props.title}
            type="file"
            onChange={(event) => {
              handleFileImport(event.currentTarget.files[0]);
              event.currentTarget.files = null;
            }}
          />
          <label htmlFor={"contained-button-file" + props.title}>
            <Button
              disabled={dataStatus === 'loading' || props.disabled}
              startIcon={props.icon}
              variant="contained"
              color="primary"
              component="span"
              style={{
                marginRight: "20px",
                width: "350px",
                justifyContent: "left",
              }}
            >
              {props.title}
            </Button>
          </label>
          <CircularProgressWithLabel value={progress} />
        </div>
        {false && <Link disabled={dataStatus === 'loading' || props.disabled} href="/files/2021-01-14 COPLUS SRK SZPITAL.xlsx" variant="body2">
          {"Pobierz plik wzorcowy"}
        </Link>}
      </Paper>
    );
  };

  const tutorData = [
    {
      id: 0,
      name: 'Wprowadzenie',
      videos: [
        { id: 0, title: '', image: '/manual/tutorial/0. Wzorcowy plik MS Excel - wstęp.jpg', video: '/manual/tutorial/0. Wzorcowy plik MS Excel - wstęp.mp4' }
      ]
    },
    {
      id: 1,
      name: 'Arkusz 1 - Słownik 4',
      videos: [
        { id: 0, title: '', image: '/manual/tutorial/1. Trzy pierwsze arkusze.png', video: '/manual/tutorial/1. Trzy pierwsze arkusze.mp4' },
        { id: 1, title: '', image: '/manual/tutorial/Arkusz 1 - Jak dodawać nowe konta zespołu 4.jpg', video: '/manual/tutorial/Arkusz 1 - Jak dodawać nowe konta zespołu 4.mp4' }
      ]
    },
    {
      id: 2,
      name: 'Arkusz 2 - Słownik 5',
      videos: [
        { id: 0, title: '', image: '/manual/tutorial/1. Trzy pierwsze arkusze.png', video: '/manual/tutorial/1. Trzy pierwsze arkusze.mp4' }
      ]
    },
    {
      id: 3,
      name: 'Arkusz 3 - Koszty',
      videos: [
        { id: 0, title: '', image: '/manual/tutorial/1. Trzy pierwsze arkusze.png', video: '/manual/tutorial/1. Trzy pierwsze arkusze.mp4' }
      ]
    },
    {
      id: 4,
      name: 'Arkusz 4 - Etap 0',
      videos: [
        { id: 0, title: '', image: '/manual/tutorial/4. Arkusz 4. Etap 0.png', video: '/manual/tutorial/4. Arkusz 4. Etap 0.mp4' }
      ]
    },
    {
      id: 5,
      name: 'Arkusz 5 - Klucze OPK Pomocnicze',
      videos: [
        { id: 0, title: '', image: '/manual/tutorial/5. Arkusz 5 KLUCZE OPK Pomocnicze.png', video: '/manual/tutorial/5. Arkusz 5 KLUCZE OPK Pomocnicze.mp4' }
      ]
    },
    {
      id: 6,
      name: 'Arkusz 6 - Klucze OPK Proceduralne',
      videos: [
        { id: 0, title: '', image: '/manual/tutorial/6. Arkusz 6 KLUCZE OPK Proceduralne.png', video: '/manual/tutorial/6. Arkusz 6 KLUCZE OPK Proceduralne.mp4' },
        { id: 1, title: '', image: '/manual/tutorial/Arkusz 6 - Jak uzupelnić arkusz dla procedur komercyjnych.jpg', video: '/manual/tutorial/Arkusz 6 - Jak uzupelnić arkusz dla procedur komercyjnych.mp4' },
        { id: 2, title: '', image: '/manual/tutorial/Arkusz 6 - Jak wpisać procedury bezpośrednio finansowanane przez NFZ.jpg', video: '/manual/tutorial/Arkusz 6 - Jak wpisać procedury bezpośrednio finansowanane przez NFZ.mp4' }
      ]
    },
    {
      id: 7,
      name: 'Arkusz 7 - Procedury',
      videos: [
        { id: 0, title: '', image: '/manual/tutorial/7. Arkusz 7 Procedury.png', video: '/manual/tutorial/7. Arkusz 7 Procedury.mp4' },
        { id: 1, title: '', image: '/manual/tutorial/7. Arkusz 7 Procedury- uaktualnienie.jpg', video: '/manual/tutorial/7. Arkusz 7 Procedury- uaktualnienie.mp4' },
        { id: 2, title: '', image: '/manual/tutorial/1. Alert merytoryczny.jpg', video: '/manual/tutorial/1. Alert merytoryczny.mp4' },
      ]
    },
    {
      id: 8,
      name: 'Arkusz 8 - Przychody',
      videos: [
        { id: 0, title: '', image: '/manual/tutorial/8. Arkusz 8 Przychody.png', video: '/manual/tutorial/8. Arkusz 8 Przychody.mp4' },
        { id: 1, title: '', image: '/manual/tutorial/Arkusz 8 - jak wpisać przychód dla OPK 507, 530 oraz 535.jpg', video: '/manual/tutorial/Arkusz 8 - jak wpisać przychód dla OPK 507, 530 oraz 535.mp4' }
      ]
    },
    {
      id: 9,
      name: 'Arkusz 9 - Osobodni oddziały',
      videos: [
        { id: 0, title: '', image: '/manual/tutorial/9. Arkusz 9 Osobodni.png', video: '/manual/tutorial/9. Arkusz 9 Osobodni.mp4' }
      ]
    },
  ];


  const TutorMenu = (props) => {

    const [selectedTopic, setSelectedTopic] = React.useState(0);
    const { content } = props;

    const TutorContent = (props) => {
      const { content } = props;
      const stopOtherVideos = (currentVideoId) => {
        tutorData[selectedTopic].videos.forEach(
          (_, id) => {
            if (currentVideoId !== id) {
              const div = document.getElementById('videoControl_' + id);
              if (div !== undefined) {
                div.pause();
                //div.currentTime = 0;
              }
            }
          }
        )
      };
      return (
        <div className='tutorial_contentPanel'>
          {content.map
            ((video, id) => (
              <div key={id} style={{ marginBottom: '20px' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '3px 0px 3px 0px', width: '60vw' }}>
                  <button
                    onClick={() => {
                      const div = document.getElementById('videoControl_' + id);
                      if (div.requestFullscreen)
                        div.requestFullscreen();
                      else if (div.webkitRequestFullscreen)
                        div.webkitRequestFullscreen();
                      else if (div.msRequestFullScreen)
                        div.msRequestFullScreen();
                    }
                    }
                    style={{ backgroundColor: 'white', color: '#0b6471', fontWeight: 'bold', textDecorationLine: 'underline' }}
                  >
                    Pełen ekran
                  </button>
                </div>
                <video id={'videoControl_' + id} controls poster={video.image} style={{ width: '60vw' }} onPlay={() => stopOtherVideos(id)} >
                  <source src={video.video} type="video/mp4" />
                  Video control not supported by browser
                </video>
              </div>
            ))}
        </div>
      )
    };

    const Row = (props) => {
      const { data } = props;
      return (
        <div
          className={
            selectedTopic === data.id ? "tutorial_header_selected" : "tutorial_header"
          }
          onClick={() => setSelectedTopic(data.id)}
        >
          {data.name}
        </div>
      );
    };

    return (
      <div className="tutorial">
        <div className="tutorial_tocPanel">
          <div className="tutorial_toc_header">Spis treści</div>
          {content.map((row, id) => (
            <Row key={id} data={row} />
          ))}
        </div>
        <TutorContent content={tutorData[selectedTopic].videos} />
      </div>
    );
  };

  return (
    <div style={{ minHeight: "calc(100vh - 90px)" }}>
      <div
        style={{
          background: "linear-gradient(to right, #0b6471, #9BC0C5)",
          display: "flex",
          height: "55px",
          width: "100%",
          backgroundColor: "#0b6471",
          color: "white",
          borderRadius: "5px",
          paddingTop: "0px",
          paddingLeft: "0px",
          alignItems: "center",
        }}
      >
        <div style={{ width: "80px", marginTop: "5px" }}>
          <CloudUploadIcon
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              fontSize: "32px",
            }}
          />
        </div>
        <div>
          <Typography variant="h5" style={{ color: "white" }}>
            IMPORT DANYCH DO RACHUNKU KOSZTÓW (SRK)
          </Typography>
        </div>
      </div>
      <p style={{ height: "5px" }} />
      <Paper
        className={classes.root}
        style={{ display: "flex", flexDirection: "column", width: "100%", height: "calc(100vh - 150px)" }}
      >
        <div>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            style={{ width: "100%" }}
            aria-label="full width tabs"
          >
            <Tab
              key="1"
              label={
                <div className={"MuiTabItem-labelGroup"}>
                  <div
                    style={{
                      fontSize: "16px",
                      textAlign: "center",
                      color: "#4E97A5",
                    }}
                  >
                    Import danych
                  </div>
                </div>
              }
            />
            <Tab
              key="2"
              label={
                <div className={"MuiTabItem-labelGroup"}>
                  <div
                    style={{
                      fontSize: "16px",
                      textAlign: "center",
                      color: "#4E97A5",
                    }}
                  >
                    Przegląd i zarządzanie danymi
                  </div>
                </div>
              }
            />
            <Tab
              key="3"
              label={
                <div className={"MuiTabItem-labelGroup"}>
                  <div
                    style={{
                      fontSize: "16px",
                      textAlign: "center",
                      color: "#FE8E3C",
                      width: '130px',
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <SchoolIcon />
                    Instrukcje
                  </div>
                </div>
              }
            />
          </Tabs>

          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel key="1" value={value} index={0} dir={theme.direction}>
              <div style={{
                padding: "10px", transition: "opacity 0.5s",
                opacity: dataStatus !== 'loading' ? "1" : "0.2",
              }}>
                <ImportFile
                  disabled={user && user["https://coplus.pl/md"].permissions.preview === 'yes'}
                  option="materiały"
                  title="Import danych"
                  icon={<ImCalculator style={{ fontSize: '16px' }} />}
                />
              </div>
              <Paper
                className={classes.root}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  minHeight: "500px",
                  marginTop: "10px",
                  padding: "10px",
                }}
              >
                {
                  dataStatus !== 'none' ?
                    <div>
                      {Expandable({ rows: tableData.slice(0, dataStatus === 'loaded' ? 3 : 2) })}
                    </div>
                    :
                    <div>
                      <strong style={{ fontSize: '16px' }}>Nowości w pliku importującym</strong>
                      <br /><br />
                      <strong>Pobierz aktualny plik importujący dane do CO+ (wersja z kwietnia 2024r.). </strong>
                      Poprzednie wersje nie będą już obsługiwane.
                      <br /><br />
                      <ul>
                        <li>
                          <a href='files/2024-04 COPLUS SRK SZPITAL.xlsx'>Plik dla szpitala</a>
                        </li>
                        <li>
                          <a href='files/2024-04 COPLUS SRK PRZYCHODNIA.xlsx'>Plik dla przychodni</a>
                        </li>
                      </ul>
                      <br />

                      Wprowadzone zmiany:<br />
                      <ul>
                        <li>Arkusze 1-9 – bez zmian</li>
                        <li>Arkusze 10 i 11 – nowe arkusze (nieobowiązkowe)</li>
                        <li>Arkusze 12 i 13 – zmieniono tylko numerację arkuszy (dotyczy CO+ Zaawansowany)</li>
                      </ul>
                    </div>
                }
              </Paper>
            </TabPanel>
            <TabPanel key="2" value={value} index={1} dir={theme.direction}>
              <Paper style={{ padding: "10px" }}>
                <PeriodsTable />
              </Paper>
            </TabPanel>

            <TabPanel key="3" value={value} index={2} dir={theme.direction}>
              <Paper style={{ padding: "0px" }}>
                <TutorMenu content={tutorData} />
              </Paper>
            </TabPanel>

          </SwipeableViews>
        </div>
      </Paper>
    </div>
  );
}
